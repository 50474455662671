import {inject, Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {EmployersVacancyApplicationDto, MonolithApiClient} from '../api/monolith-api-client';
import {IdentityService} from '../../../users/core/identity.service';
import {PagedArray} from '../../../../infrastructure/api/PagedArray';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {User} from '../../../../infrastructure/auth/models/user';

@Injectable({
    providedIn: 'root',
})
export class EmployerVacancyApplicationsService {
    private readonly refreshSubject$: Subject<void> = new Subject<void>();
    private readonly applicationsPageSize = 10;
    private readonly catalogClient = inject(MonolithApiClient);
    private readonly identityService = inject(IdentityService);

    public get refreshes$(): Observable<void> {
        return this.refreshSubject$.asObservable();
    }

    public getApplications(vacancyId: number, pageNumber: number): Observable<PagedArray<EmployersVacancyApplicationDto>> {
        return this.getUser().pipe(
            switchMap((user) => this.catalogClient.employerOwnVacancyApplications_GetApplications(
                    user.Id,
                    vacancyId,
                    pageNumber,
                    this.applicationsPageSize,
                ),
            ),
            map((response) => (PagedArray.fromSwaggerResponse(response))),
        );
    }

    public getUnreadApplicationsCount(): Observable<number> {
        return this.getUser().pipe(
            switchMap((user) => this.catalogClient.employerOwnVacancyApplications_UnreadApplicationsCount(user.Id, null)),
        );
    }

    public markAsReadAllApplications(): Observable<void> {
        return this.getUser().pipe(
            switchMap((user) =>
                this.catalogClient.employerOwnVacancyApplications_MarkApplicationsAsRead(user.Id, null, null),
            ),
            tap(() => this.refreshSubject$.next()),
        );
    }

    private getUser(): Observable<User> {
        return this.identityService.stateChanges.pipe(
            take(1),
            map((authState) => authState.user),
            map((user) => {
                if (!!user) return user;
                throw new Error('User is not authenticated');
            }),
        );
    }
}
